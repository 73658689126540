import { SVGAttributes } from "react";

const PlayStoreSVG = ({
  width = 24,
  height = 26,
  className = "h-[26px] w-6 shrink-0 basis-6 fill-white stroke-azure-100",
  ...props
}: {
  width?: number;
  height?: number;
  className?: string;
  props?: SVGAttributes<SVGElement>;
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.76066 1.37094C1.4916 1.65857 1.3335 2.10402 1.3335 2.68207V23.3183C1.3335 23.8964 1.4916 24.3418 1.76066 24.6294L1.82908 24.6967L13.2746 13.1365V12.8639L1.82908 1.3037L1.76066 1.37094Z"
        fill="inherit"
      ></path>
      <path
        d="M17.0906 16.9921L13.2767 13.1371V12.8645L17.0925 9.01046L21.6988 11.6533C22.9895 12.3947 22.9895 13.6069 21.6988 14.3474L17.1785 16.9417L17.0906 16.9921Z"
        fill="inherit"
      ></path>
      <path
        d="M17.1785 16.9417L13.2765 13.0002L1.76255 24.6295C2.18878 25.0842 2.89147 25.1403 3.68293 24.6864L17.1785 16.9417Z"
        fill="inherit"
      ></path>
      <path
        d="M17.1783 9.05893L3.68293 1.31357C2.89147 0.859719 2.18878 0.91575 1.76255 1.37054L13.2765 13.0002L17.1783 9.05893Z"
        fill="inherit"
      ></path>
      <path
        d="M17.1785 16.9417L21.6988 14.3474C22.9895 13.6069 22.9895 12.3947 21.6988 11.6533L17.0925 9.01046L13.2767 12.8645V13.1371L17.0906 16.9921L17.1785 16.9417ZM17.1785 16.9417L13.2765 13.0002M17.1785 16.9417L3.68293 24.6864C2.89147 25.1403 2.18878 25.0842 1.76255 24.6295L13.2765 13.0002M13.2765 13.0002L1.76255 1.37054C2.18878 0.91575 2.89147 0.859719 3.68293 1.31357L17.1783 9.05893L13.2765 13.0002ZM1.76066 1.37094C1.4916 1.65857 1.3335 2.10402 1.3335 2.68207V23.3183C1.3335 23.8964 1.4916 24.3418 1.76066 24.6294L1.82908 24.6967L13.2746 13.1365V12.8639L1.82908 1.3037L1.76066 1.37094Z"
        stroke="inherit"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default PlayStoreSVG;
